.sidebar {
    height: 100%;
    border: 1px solid #e0e0e0;
    padding: 0 20px;
}

.sidebar.expanded {
    max-width: 267px;
}

.sidebar.collapsed {
    max-width: 82px;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-logo {
    padding: 20px 0;
}

.menu-title-container {
    display: flex;
    background-color: var(--A1);
    color: var(--A2);
    padding: 3px;
    margin: 0;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    align-items: center;
    margin: 0;
}

.menu-title-container img {
    margin: 5px 10px 0 10px;
    padding: 7px 0;
}

.menu-title {
    font-size: 20px;
    font-weight: bold;
    background-color: var(--A1);
    color: var(--A2);
    margin: 0px 20px 0px 0px;
    padding-left: 10px;
    height: 20px;
}

.submenu-container {
    background-color: #f8f8f8;
    margin: 0;
    list-style-type: none;
    border-radius: 0 0 10px 10px;
    border: 1px solid #ccc;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in;
}

.submenu-container.expanded {
    max-height: 1250px;
}

.menu-item-container {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.menu-item-container:hover {
    background-color: #ffffff;
}

.menu-item {
    text-align: left;
}

.menu-item.selected {
    font-weight: bold;
}

.other-links-item-container {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px;
}

.other-links-item-container img {
    margin-right: 10px;
}

.other-links-item-container p {
    margin: 0;
    padding: 0;
    text-align: left;
}

.arrow-icon {
    transition: transform 0.3s ease;
    transform: rotate(180deg);
}

.arrow-icon.expanded {
    transform: rotate(0deg);
}

.sidebar-logo-expanded{
    width: 80%;
}

.sidebar-logo-collapsed{
    width: 100%;
}
